<script>
import AppFooter from '@/components/AppFooter'

export default {
  name: 'skiservis',
  components: {
    AppFooter
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    },
    openModal(n) {
      this.$refs[`modal${n}`].open()
    }
  }
};
</script>

<template>
  <div class="pozicovna-view">
    <article>
      <div class="content-wrapper">
        <h1>SKISERVIS</h1>
        
        <h2>Najmodernejšie stroje MONTANA na servis lyží a snb</h2>

        <img src="/images/skiservis/montana-logo-white.png" style="float:right;margin:0 0 0 2rem;width:auto;height:4.5rem" alt="Montana logo" width="500" height="480" />
        <p>Ponúkame kvalitný servis lyží a snowboardov na strojoch značky MONTANA. Servisu lyží sa venujeme už 26 rokov a za tento čas sa neustále snažíme držať posledných trendov a technológií. Disponujeme moderným automatickým strojom na servis lyží a snowboardov od jedných z najlepších tomto odbore - renomovaného švajčiarskeho výrobcu MONTANA. Naši kvalifikovaný pracovníci Vám výstroj pripravia pre čo najlepší zážitok na svahu alebo presne podľa Vašich požiadaviek.</p>

      </div>

      <div class="full-box full-box-montana">
        <div class="full-box-content">
          <h3>Náš stroj Montana Challenge</h3>
          <p><font-awesome-icon icon="check-circle" /> profesionálny počítačom riadený automatický stroj</p>
          <p><font-awesome-icon icon="check-circle" /> široký brúsny kameň (350×320×152mm)</p>
          <p><font-awesome-icon icon="check-circle" /> agregáty pre komplexnú úpravu hrán všetkých typov lyží a snowboardov</p>
          <p><font-awesome-icon icon="check-circle" /> moderný vysokokvalitný systém finálnej úpravy plochy sklznice</p>
          <p><font-awesome-icon icon="check-circle" /> nastavenie štruktúry sklznice: pozdĺžna, priečna, krížová a variálna</p>
          <p><font-awesome-icon icon="check-circle" /> bočné uhlovanie hrany a následný odklon a leštenie spodnej hrany</p>
          <p><font-awesome-icon icon="check-circle" /> ideálne prevedenie servisu hrany v celej jej dĺžke vrátane špičky</p>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="video-wrapper">
          <iframe width="640" height="360" src="https://www.youtube.com/embed/mC8psRQXTks" frameborder="0" loading="lazy"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
        </div>
        <h2>Čo z toho mám alebo Prečo servisovať lyže?</h2>
        <p>Pre lyžiara je nejdôležitejšia perfektná funkčnosť vybavenia. Jedine precízne pripravené vybavenie garantuje plnohodnotnú zábavu na snehu, čo znamená jednoduché ovládanie, presné reakcie na akúkoľvek mzenu smeru, vysokú stabilitu pri jazde a perfektný sklz. Rozhodujúcim dôvodom však stále zostáva vlastná bezpečnosť!</p>
        <Imgix src="skiservis/struktury.jpg" class="structures" alt="Štruktúry upravenej sklznice" loading="lazy" width="1240" height="944" />
        <p>Našim doporučením preto je, že servis vybavenia by nemal prebiehať sporadicky, obmedzený len na krízové situácie, ale mal by byť kontinuálny. Pravidelný servis lyží/snowboardu je predpokladom väčších zážitkov z jazdy, športových výkonov, ale hlavne i osobnej bezpečnosti. Štrukturovanie plochy lyže vrátane uhlovania hrany a impregnácie voskom sa zpravidla vykonáva niekoľkokrát za zimnú sezónu s ohľadom na frekvenciu použitia a snehové podmienky v danej oblasti (kamene na svahu, prírodný sneh, technický sneh a pod.).</p>
        <p>Úplne nevyhnutné je, aby lyže či snowboard boli pripravené pred začiatkom zimnej sezóny. V tomto smere odporúčame uskutočniť veľký servis vždy na konci sezóny tak, aby impregnačná vosková vrstva na ploche sklznice nebola úplne stiahnutá, ale naopak ponechaná z dôvodu letného zakonzervovania sklznice a spodnej hrany - finálne vyleštenie vosku odporúčame následne až pred prvým lyžovaním.</p>
        <p class="clear"></p>

        <h2>Cenník ponúkaných služieb</h2>
        <table class="pricelist">
          <tbody>
          <tr class="title">
            <th style="text-align: left;">Lyže</th>
            <td></td>
          </tr>
          <tr>
            <th style="text-align: left;">
              štruktúra
            </th>
            <td>+ 10 €</td>
          </tr>
          <tr>
            <th style="text-align: left;">
              infra vosk
            </th>
            <td>+ 5 €</td>
          </tr>
          <tr class="title">
            <th style="text-align: left;">Lyže nad 130 cm</th>
            <td></td>
          </tr>
          <tr>
          </tr><tr>
            <th style="text-align: left;">
              malý servis 
              <b class="cislo2" @click="openModal('2')">2</b>
              <b class="cislo3" @click="openModal('3')">3</b>
              <b class="cislo4" @click="openModal('4')">4</b>
              <b class="cislo5" @click="openModal('5')">5</b>
            </th>
            <td>25 €</td>
          </tr>
          <tr>
            <th style="text-align: left;">
              veľký servis 
              <b class="cislo1" @click="openModal('1')">1</b>
              <b class="cislo2" @click="openModal('2')">2</b>
              <b class="cislo3" @click="openModal('3')">3</b>
              <b class="cislo4" @click="openModal('4')">4</b>
              <b class="cislo5" @click="openModal('5')">5</b>
            </th>
            <td>30 €</td>
          </tr>
          <tr>
            <th style="text-align: left;">
              pretekársky servis 
              <b class="cislo1" @click="openModal('1')">1</b>
              <b class="cislo2" @click="openModal('2')">2</b>
              <b class="cislo3" @click="openModal('3')">3</b>
              <b class="cislo4" @click="openModal('4')">4</b>
              <b class="cislo5" @click="openModal('5')">5</b>
              <b class="cislo6" @click="openModal('6')">6</b>
            </th>
            <td>40 €</td>
          </tr>
          <tr class="title">
            <th style="text-align: left;">Lyže, Snowboard, Free ride, Široké lyže, Twintip</th>
            <td></td>
          </tr>
          <tr>
          </tr><tr>
            <th style="text-align: left;">
              malý servis 
              <b class="cislo2" @click="openModal('2')">2</b>
              <b class="cislo3" @click="openModal('3')">3</b>
              <b class="cislo4" @click="openModal('4')">4</b>
              <b class="cislo5" @click="openModal('5')">5</b>
            </th>
            <td>30 €</td>
          </tr>
          <tr>
            <th style="text-align: left;">
              veľký servis 
              <b class="cislo1" @click="openModal('1')">1</b>
              <b class="cislo2" @click="openModal('2')">2</b>
              <b class="cislo3" @click="openModal('3')">3</b>
              <b class="cislo4" @click="openModal('4')">4</b>
              <b class="cislo5" @click="openModal('5')">5</b>
            </th>
            <td>35 €</td>
          </tr>
          <tr>
            <th style="text-align: left;">
              kluby 
              <b class="cislo1" @click="openModal('1')">1</b>
              <b class="cislo2" @click="openModal('2')">2</b>
              <b class="cislo3" @click="openModal('3')">3</b>
              <b class="cislo4" @click="openModal('4')">4</b>
              <b class="cislo5" @click="openModal('5')">5</b>
            </th>
            <td>25 €</td>
          </tr>
          <tr>
            <th style="text-align: left;">
              lyže do 130 cm
            </th>
            <td>- 5 €</td>
          </tr>
        </tbody></table>

        <h2>Servisný postup a technológie</h2>
        <p>Pre docielenie 100% kvality servisu lyží a snowboardov je dôležité splniť postupnosť nasledujúcich servisných úkonov:</p>
        <div class="boxes">
          <div @click="openModal(1)" class="box" style="background-color:#01a0e2">
            <div class="title"><h3>1. Základné predbrúsenie a oprava sklznice</h3></div>
            <p>Prebrúsenie plochy sklznice na brúsnom páse slúži k odstráneniu starého vosku a zvýrazneniu potrebných miest budúcej opravy&hellip;</p>
            <sweet-modal title="Základné predbrúsenie a oprava sklznice" ref="modal1">
              <font-awesome-icon icon="comment" />
              <p class="line">Prebrúsenie plochy sklznice na brúsnom páse slúži k odstráneniu starého vosku a zvýrazneniu potrebných miest budúcej opravy.</p>
              <p class="line">Pre dokonalé odmastenie sklznice pred jej opravou používame odstraňovač vosku.</p>
              <p class="line">Mechanické vyčistenie poškodenej sklznice (škrabance a ryhy) zaručí dokonalé spojenie novonatavovaného P–tex materiálu s pôvodnou sklznicou.</p>
              <p class="line">Pomocou ručných pištolí alebo strojných extrúderov Montana nanášame na plochu sklznice len nevyhnutnú vrstvu taveného polyethylenu. Takto natavená plocha môže být okamžite brúsena na brúsnom kameni, bez toho aby bolo potrebné jej zbrúsenie ručným náradím.</p>
            </sweet-modal>
          </div>

          <div @click="openModal(2)" class="box" style="background-color:#4caf50">
            <div class="title"><h3>2. Finálne brúsenie</h3></div>
            <p>Veľmi dôležitá časť servisného postupu, ktorá zásadne ovplyvňuje sklzové vlasntosti plochy sklznice&hellip;</p>
            <sweet-modal title="Finálne brúsenie" ref="modal2">
              <font-awesome-icon icon="comment" />
              <p class="line">Veľmi dôležitá časť servisného postupu, ktorá zásadne ovplyvňuje sklzové vlasntosti plochy sklznice (odvod vody tvoriacej sa pod sklznicou).</p>
              <p class="line">Na ideálne rovnú plochu sklznice „rysujeme“ finálnu štruktúru použitím brúsneho kameňa s obmenou rýchlosti jeho otáčiek, rýchlosti posunu a prestavením jeho povrchovej štruktúry – orovnanie kameňa diamantom</p>
              <p class="line">Podľa charakteru lyže a snehového podkladu volíme adekvátny typ požadovanej štruktúry – lineárna, kombinovaná alebo variálna, a ďalej následne hrubá, stredná alebo jemná.</p>
            </sweet-modal>
          </div>

          <div @click="openModal(3)" class="box" style="background-color:#f71735">
            <div class="title"><h3>3. Uhlovanie bočnej hrany</h3></div>
            <p>Uhol bočnej hrany závisí na type a charakteri použitia lyže/snowboardu, skúsenostiach užívateľa a tvrdosti snehovej podložky&hellip;</p>
            <sweet-modal title="Uhlovanie bočnej hrany" ref="modal3">
              <font-awesome-icon icon="comment" />
              <p class="line">Uhol bočnej hrany závisí na type a charakteri použitia lyže/snowboardu, skúsenostiach užívateľa a tvrdosti snehovej podložky.</p>
              <p class="line">Bočné uhlovanie hrany - základné uhlovanie sa vykonáva pomocou brúsneho pásiku v rozsahu 89 – 87°. Správne zvolený uhol umožňuje dokonalý kontakt hrany so snehovou podložkou v priebehu celého oblúku v závislosti na charakteri lyže.</p>
              <p class="line">Pre závodný servis je základne pripravená (nauhlovaná) bočná hrana ešte vyleštená pomocou brúsneho kameňa – tuning bočnej hrany. Slúži k docieleniu ostrého vrcholu uhlu hrany a zároveň k jej vyhladeniu, teda vymazaniu štruktúry po brúsnom pásiku.</p>
              <p><b>Odporúčame:</b></p>
              <div class="padded">
                89° – pre začínajúcich lyžiarov alebo seniorov, snb FS/FR<br>
                88° – štandardné uhlovanie bočnej hrany pre priemerného lyžiara i športového lyžiara, snb Slalom/SnbCross (vyznačuje sa dostatočnou ostrosťou na zamrznutom alebo technickom snehu a dlhou životnosťou)<br>
                87° – pre lyžiarov pretekárov a expertov
              </div>
              <p class="line"></p>
              <p class="line">Uhlovaním hrany len z boku garantujeme veľmi ostrú až agresívnu hranu vybavenia. Odporúčame len pre lyžiarov/snowboardistov na veľmi vysokej športovej úrovni (pre pretekárske lyžovanie na úrovni svetový, európsky pohár).</p>
            </sweet-modal>
          </div>

          <div @click="openModal(4)" class="box" style="background-color:#41ead4">
            <div class="title"><h3>4. Tuning spodnej hrany</h3></div>
            <p>Je neoddeliteľnou súčasťou kompletnej úpravy hrany. Slúži k prenosu účinnosti bočného uhlu hrany na snehovú podložku&hellip;</p>
            <sweet-modal title="Tuning spodnej hrany" ref="modal4">
              <font-awesome-icon icon="comment" />
              <p class="line">Je neoddeliteľnou súčasťou kompletnej úpravy hrany. Slúži k prenosu účinnosti bočného uhlu hrany na snehovú podložku, a to hlavne pri jazde po hrane v carvingovom oblúku a zabraňuje nežiadúcemu zarezávaniu hrán.</p>
              <p class="line"><b>Odklon</b> – tuning spodnej hrany smerom od podložky je možný len na základe presnej roviny sklznice a hrany.</p>
              <p class="line">Tuning hrany sa vykonáva pomocou paralelných brúsnych kameňov s konštantným uhlom sklonu, rýchlosťou posunu a jeho tlaku.</p>
              <p class="line"><b>Podbúsením</b> - vyleštením spodnej hrany zároveň odstránime zbytkový otrep, ktorý vznikol pri bočnom brúsení.</p>
              <p class="line"><b>Radiálny tuning</b> – alebo tiež premenlivý tuning spodnej hrany je novinkou v oblasti tuningu spodnej hrany, kedy sa stupeň odklonu spodnej hrany pohybuje v rozmedzí 0,4° až 0,8° v závislosti na vykrojení lyží. Radiálny tuning sa vyznačuje ľahším nástupom lyže do oblúku a stabilitou v priebehu celého oblúku. Nie je určený len pre športových lyžiarov, ale i pre širokú lyžiarsku verejnosť.</p>
              <p class="line"><b>Konštantný tuning</b> – rovnomerný tuning spodnej hrany po celej dĺžke lyže; pohybuje sa od 0,5°do 1° (najpoužívanejší je 0,5°).</p>
            </sweet-modal>
          </div>

          <div @click="openModal(5)" class="box" style="background-color:#ff9f1c">
            <div class="title"><h3>5. Voskovanie a leštenie</h3></div>
            <p>Po finálnom brúsení je nevyhnutná impregnácia sklznice voskom. Samotné brúsenie je vždy veľkým zásahom&hellip;<br><br></p>
            <sweet-modal title="Voskovanie a leštenie" ref="modal5">
              <font-awesome-icon icon="comment" />
              <p class="line">Po finálnom brúsení je nevyhnutná impregnácia sklznice voskom. Samotné brúsenie je vždy veľkým zásahom do jednotlivých pórov sklznice. Základná impregnácia je zvlášt potrebná k hĺbkovej ochrane pórov a k zaisteniu ich elasticity.</p>
              <p>Nanesenie vosku je možné niekoľkými spôsobmi:</p>
              <div class="padded">- horúcou valcovou kefou<br>
                - silikónovým valcom ponoreným vo voskovom kúpeli<br>
                - bezkontaktným mazaním (infra lampa)
              </div>
              <p class="line"></p>
              <p class="line">Po namazaní sklznice dostanete úplne iný pocit z jazdy. Jazda sa stáva omnoho zábavnejšia, jednoduchšia a rýchlejšia. Výsledný pohyb na svahu je potom elegantnejší a technika jazdy lepšia.</p>
            </sweet-modal>
          </div>

          <div class="box" style="background-color: #CDDC39">
            <div class="title"><h3>6. Race edge</h3></div>
            <p>Dokonale ostrá hrana a preteková štruktúra sklznice&hellip;<br><br></p>
          </div>
        </div>
      </div>

      <div class="full-box full-box-night-service bg-night-service-back">
        <div class="full-box-content full-box-content-dark">
          <h3 style="color: #ffee56">Nočný servis</h3>
          <p>Nechajte Vaše lyže alebo snowboard na recepcii (vyzdvihneme ich v čase 17:00 - 20:00), ráno na Vás počkajú pripravené v perfektnom stave (7:30 - 8:30).</p>
          <p>Kontaktujte NIGHT SERVIS - na čísle <a href="tel:+4210905357133" title="Zavolaje">0905 357 133</a>.</p>
        </div>
      </div>
    </article>

    <app-footer>
      <b>Servis lyží a snowboardov:</b>
        <ul>
          <li><a @click="openModal('1')">Základné predbrúsenie a oprava sklznice</a></li>
          <li><a @click="openModal('2')">Finálne brúsenie</a></li>
          <li><a @click="openModal('3')">Uhlovanie bočnej hrany</a></li>
          <li><a @click="openModal('4')">Tuning spodnej hrany</a></li>
          <li><a @click="openModal('5')">Voskovanie a leštenie</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #0968aa;

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .video-wrapper {
    float: right;
    margin: 0 0 20px 20px;

    @media (max-width: 720px) {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      margin: 0;
      float: none;
    }

    .video {
      @media (max-width: 720px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .clear {
    clear: both;
  }

  .structures {
    width: auto;
    margin: 1rem 0;
    
    @media (min-width: 600px) {
      float:left;
      margin:0 2rem 1rem 0;
      width: 450px;
    }
  }

  .pricelist {
    margin: 1rem auto;
    padding: 0;
    width: 60%;
    font-size: 1rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    border-collapse: collapse;

    @media (max-width: 720px) {
      width: 90%;
    }

    tr {
      border-bottom: 1px solid #eee;

      &.title {
        background: #d9f3ff;
        border-bottom: none;
      }

      td {
        vertical-align: middle;
        padding: .75rem;
      }

      th {
        font-weight: 300;
        vertical-align: middle;
        padding: .35rem .75rem;
      }
    }

    .cislo1, .cislo2, .cislo3, .cislo4, .cislo5, .cislo6 {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 1rem;
      background: #666;
      text-align: center;
      vertical-align: bottom;
      color: #fff;
      font-size: .85rem;
      margin: 0 0 0 .4rem;
      cursor: pointer;
    }

    .cislo1 {
      background: #01a0e2;
    }

    .cislo2 {
      background: #4caf50;
    }

    .cislo3 {
      background: #f71735;
    }

    .cislo4 {
      background: #41ead4;
    }

    .cislo5 {
      background: #ff9f1c;
    }

    .cislo6 {
      background: #CDDC39;
    }
  }

  .boxes {
    .box {
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        transform: rotateZ(.5deg);
        z-index: -10;
        filter: blur(3px);
        border-radius: 5px;
      }      

      ::v-deep .title {
        height: 40%;
        background: rgba(0, 0, 0, 0.15) !important;
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;

        h3 {
          font-family: "Montserrat";
          font-size: 20px;
          color: #fff;
          margin: 0;
          padding: .5rem 1rem;
          text-transform: uppercase;
        }
      }

      ::v-deep .sweet-content {
        [data-icon] {
          display: none;
        }

        aside {
          float: right;

          img {
            width: 450px;
            margin-left: 20px;
          }
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;

          &.line {
            border-bottom: 1px solid #eee;
          }          
        }

        .padded {
          padding-left: 2rem;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          clear: right;
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }
    }
  }

  .full-box {
    padding: 0 3%;

    .full-box-content {
      .mobil-rent {
        position: absolute;
        left: 95px;
        top: -30px;
        z-index: 1;
      }
    }

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    a {
      color: #0968aa;
      background: #fff;
      border-radius: 5px;
      padding: 0.1rem;
    }

    &.full-box-night-service {
      .full-box-content {
        @media (max-width: 720px) {
          background: none;
        }
      }
      p {
        color: #fff;

        @media (min-width: 720px) {
          width: 50%;
        }
      }
    }

    &.full-box-montana {
      padding: 2rem 3%;
      margin-top: 2rem;
      background-image: url(https://skiservis.imgix.net/skiservis/montana-stroj.jpg?auto=format&dpr=1);
      background-color: #959595;

      @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
          background-image: url(https://skiservis.imgix.net/skiservis/montana-stroj.jpg?auto=format&dpr=2);
      }

      @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
          background-image: url(https://skiservis.imgix.net/skiservis/montana-stroj.jpg?auto=format&dpr=3);
      }

      .full-box-content {
        @media (max-width: 720px) {
          background: rgba(0, 0, 0, 0.6);
        }
      }

      h3 {
        color: #ddd;
      }

      p {
        color: #fff;
      }
    }
  }
</style>
